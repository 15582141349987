.auth-container {
    flex-grow: 1;
    flex-direction: column;
}

.auth-main-container {
    display: flex;
    flex-direction: column;
    width: 25rem;
    padding: 2rem;
    gap: 2rem;
    box-shadow: rgba(0, 0, 128, 0.8) 0px 7px 29px 0px;
    border-radius: 1rem;
    margin-top: 6rem;
    padding-bottom: 0.5rem;
}

.auth-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    color: black;
}

.auth-main,
.auth-checkbox,
.first-last-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.3rem;
    font-size: 0.8rem;
}

.first-last-wrapper {
    flex-direction: row;
    gap: 0.5rem;
}

.first-last-wrapper .text-input {
    width: 100%;
}

.auth-main-container input {
    border-radius: 0.5rem;
    border: 2px solid var(--lightGrey);
    font-size: 0.95rem;
    padding: 0.25rem 1rem;
    cursor: text;
    font-family: "Montserrat", sans-serif;
}

.auth-main-container input:hover {
    border: 2px solid var(--main-color);
}

.auth-email,
.auth-pwd,
.auth-firstname,
.auth-lastname {
    display: flex;
    flex-direction: column;
}

.auth-checkbox {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 0.1rem;
}

.auth-checkbox label {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    cursor: pointer;
}

.auth-checkbox a {
    color: var(--main-color);
    margin-left: 1rem;
}


.auth-secondary-btn {
    cursor: pointer;
}

.auth-secondary-btn a {
    font-size: 1rem;
    color: var(--black);
}

.auth-main label {
    margin-bottom: 0.5rem;
    font-weight: bold;
}


@media screen and (max-width: 500px) {
    .auth-main-container {
        width: 20rem;
        padding: 1rem;
    }

    .auth-main {
        gap: 1rem
    }
}

/* Additional Information Style */
.auth-container p {
    text-align: center;
    margin-bottom: 0.2rem;
    color: #020911;
    font-size: 1.5rem;
}

.auth-container h3 {
    text-align: center;
    margin-bottom: 0rem;
    color: #000e3d;
    margin-top: 2.5rem;
    font-size: 3rem;
}

/* Additional Styles */
.text-center {
    text-align: center;
}