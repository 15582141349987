body {
    color: var(--white);
    font-family: "Montserrat", sans-serif;
    background-image: url(../../assets/bgnew.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}


.home-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding-top: 0.5rem;
}

.nav {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    max-width: 1400px;
    margin: 0 2rem;
    width: 100%;
    font-size: 1.3rem;
    align-items: flex-end;
}

.nav-logo {
    display: flex;
    align-items: flex-end;
    color: var(--primary-color);
    letter-spacing: 2px;
    font-size: 1.75rem;
}


.logo-img {
    width: 3.5rem;
    height: 3.5rem;
    padding-bottom: 0;
    margin-right: 0.5rem;
    margin-bottom: 0;
}

.logo-text {
    margin: auto 0.5rem;
}


.main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50vh;
    position: relative;
    margin: 0 auto;
    padding: 0 5rem;
    margin-top: 7rem;
}

.main-text {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main-text-title {
    font-size: 2.5rem;
    text-align: center;
    max-width: 650px;
    line-height: 3.5rem;
    letter-spacing: 0.05rem;
    padding: 0.5rem;
}

.main-text-span {
    color: var(--primary-color);
    font-weight: bold;
}

.main-link {
    text-decoration: none;
}

.main-btn {
    background: none;
    border: 3px solid var(--white);
    font-family: "Montserrat", sans-serif;
    color: var(--white);
    font-weight: 600;
    padding: 1rem 2rem;
    margin: 1rem;
    font-size: larger;
    cursor: pointer;
}

.main-btn:hover {
    background: rgba(17, 30, 67, 0.5);
}

.main-footer {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
}

.footer-text {
    font-size: 1.25rem;
}

.footer-links {
    margin: 1rem auto;
}

.github i,
.twitter i,
.linkedin i {
    color: var(--white);
    font-size: 1.75rem;
    padding: 0.15rem 0.7rem;
}

.github i:hover,
.twitter i:hover,
.linkedin i:hover {
    transform: scale(1.25);
}