.sidebar {
  height: calc(100vh);
  width: 15rem;
  padding-top: 2rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 3px 2px 0px;
  display: none;
  flex-direction: column;
  z-index: 3;
}

.sidebar.active {
  display: flex;
}

.sidebarListItems {
  display: flex;
  padding: 1.5rem 2.5rem;
  cursor: pointer;
}

.sidebarListItems:hover {
  transform: scale(1.025);
}

.sidebarText {
  font-size: 1.2rem;
  padding-left: 2rem;
  text-decoration: none;
}

.sidebarListItems i{
  font-size: 1.5rem;
}

@media screen and (max-width: 900px) {
  .sidebar.active {
    position: fixed;
    top: 5.9%;
  }
}
