@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

:root{
    font-family: "Montserrat", sans-serif;
}

body{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

a {
    color: inherit;
    text-decoration: inherit;
  }
  
  li {
    list-style: none;
  }
  
  button:disabled{
    background:var(--lightGrey)
  }

.toastBody {
  font-family: "Montserrat", sans-serif;
} 
