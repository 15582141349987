.filter-container {
  box-shadow: rgba(0, 0, 0, 0.8) 0px 1px 4px;
  position: fixed;
  left: 82%;
  bottom: 45%;
  display: none;
  flex-direction: column;
  border-radius: 5px;
  max-width: 15rem;
  width: 100%;
  margin: auto;
  padding: 1rem;
  background-color: white;
  color: black !important;
  
}

.filter-container-active{
    display: flex;
}

.filter-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.filter-clear-btn{
    border-radius: 5px;
    border: none;
    padding: 5px 12px;
    cursor: pointer;
}

.filter-ipt-container{
    margin-bottom: 1rem;
}

.filter-ipt{
    display: flex;
    gap: 5px;
    align-items: center;
    margin: 5px 0;
    padding-left: 5px;
}

@media screen and (max-width: 1200px) {
    .filter-container {
        bottom: 55%;
    }
}

@media screen and (max-width: 700px) {
    .filter-container {
        left: 20%;
    }
}
