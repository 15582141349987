.contaiiner {
  display: flex;
  justify-content: flex-start;
  height: calc(100vh - 5rem);
}

.sub-container {
  width: 100vw;
  height: calc(100vh - 5rem);
  display: flex;
  flex-direction: column;
}

.create-note-head {
    display: flex;
    padding: 1rem;
    justify-content: space-between;
}

.create-note-head h1{
    font-size: 2.5rem;
}

.note-head-action{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 2rem;
}

.btn {
  padding: 5px 10px;
  margin: 0%;
  padding-right: 15px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  color: var(--black-color);
}
.note-btn{
    background-color: var(--black-color);
    color: var(--white-color);
}

.saved-notes{
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.notes-category{
  display: flex;
  flex-direction: column;
  padding: 3rem;
  gap: 1.5rem;  
}

.notes-category h2{
  color: var(--ligtest-color);
  font-weight: 300;
}

/* tag */

@media screen and (max-width: 500px) {
  .btn {
    padding: 2px 5px;
    padding-right: 10px;
  }
}
